<template>
  <div class="minWidth1000">
    <custom-actions show-back>
      <el-form class="form" :model="form" :rules="rules" ref="form" label-width="140px">
        <el-form-item label="姓名" prop="personName">
          <el-input v-model="form.personName" placeholder="请输入姓名"/>
        </el-form-item>
        <el-form-item label="登录账号" prop="loginId">
          <el-input v-model="form.loginId" placeholder="请输入登录账号"/>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" maxlength="11" placeholder="请输入手机号"/>
        </el-form-item>
        <el-form-item v-if="!userId" label="密码" prop="password">
          <el-input v-model="form.password" type="password" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="form.roleId">
            <el-option :label="item.roleName" :value="item.roleId" v-for="(item, index) of roles" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属基站" prop="deptIds">
          <el-cascader
            v-model="form.deptIds"
            :show-all-levels="false"
            :options="depts"
            filterable
            :props="{ multiple: true, emitPath: false, label: 'deptName', value: 'deptId', children: 'childNode', checkStrictly: true }">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit" class="global-button">保存</el-button>
          <el-button @click="$router.back()" class="global-button">取消</el-button>
        </el-form-item>
      </el-form>
    </custom-actions>
  </div>
</template>

<script>
export default {
  name: 'AccountAdd',
  data () {
    return {
      userType: [],
      roles: [],
      depts: [],
      form: {
        loginId: '', // 登录账号
        mobile: '', // 手机号
        roleId: '', // 用户角色
        password: '', // 密码
        personName: '', // 姓名
        deptIds: '' // 基站id
      },
      rules: {
        loginId: [{ required: true, message: '请输入登录名' }],
        mobile: [{ required: true, message: '请输入手机号' }],
        roleId: [{ required: true, message: '请选择角色' }],
        password: [{ required: true, message: '请输入密码' }],
        personName: [{ required: true, message: '请输入姓名' }],
        deptIds: [{ type: 'array', required: true, message: '请选择基站' }]
      }
    }
  },
  created () {
    this.loadRoles()
    this.loadDepts()
    if (this.userId) {
      this.loadDetails()
    }
  },
  computed: {
    userId () {
      return this.$route.query.userId
    }
  },
  methods: {
    loadDetails () {
      this.$axios.get(this.$apis.system.selectSysUserByUserId, {
        params: { userId: this.userId }
      }).then(res => {
        if (res.deptId && !res.deptIds.length) {
          res.deptIds = [res.deptId]
        }
        this.form = res
      })
    },
    submit () {
      this.$refs.form.validate().then(() => {
        let url = this.$apis.system.insertSysUser
        if (this.userId) {
          url = this.$apis.system.updateSysUser
        }
        this.$axios.post(url, this.form).then(res => {
          this.$router.back()
        })
      })
    },
    loadRoles () {
      this.$axios.get(this.$apis.system.selectSysRoleByList).then(res => {
        this.roles = res
      })
    },
    loadDepts () {
      this.$axios.get(this.$apis.system.selectSysDeptByList).then(res => {
        this.depts = res
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.el-input,
.el-select,
.el-cascader {
  width: 250px;
}
</style>
